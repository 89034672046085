<template>
  <LoadingSpinner v-show="isProcessing" />
  <!-- <div class="marginX body fixed mobile">
    <section class="header receipt">
      <div class="flexH width">
          <a class="icon iconButton third" @click.prevent="goBack">
              <i class="fas fa-chevron-left fa-lg"></i>
          </a>
      </div>
      <span class="fL nowrap">手機條碼載具</span>
      <div class="flexH width right">
          <a class="textButton fM third"  @click.prevent="popDelete">解除綁定</a>
      </div>
    </section>
    <section class="main receipt">
      <div class="info flexV width padding center gapM">
        <div class="input right rounded">
          <div class="icon"><i class="fas fa-barcode"></i></div>
          <span class="fM nowrap gray">載具條碼</span>
          <input
              name="cardNo"
              type="text"
              placeholder="輸入手機條碼載具"
              v-model="user.cardNo"
              @input="isCarrierBarcode"
            />        </div>
        <div class="input right rounded">
          <div class="icon"><i class="fas fa-key"></i></div>
          <span class="fM nowrap gray">載具驗證碼</span>
          <input
              name="cardEncrypt"
              type="password"
              placeholder="輸入驗證碼"
              v-model="cardEncrypt"
              @change="isCardEncrypt"
            />
        </div>
        <a
          class="textButton fS gray under"
          href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC511W/"
          target="_blank"
          >
          忘記驗證碼
        </a>
        <br />
        <a class="button rounded" @click="save">
          <span class="fM">綁定</span>
        </a>
        <span class="fS gray">
          載具條碼請至
          <a class="textButton" 
            href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC501W/"
            target="_blank"
          >
            財政部電子發票整合服務平台 
          </a>
          進行申請
        </span>
      </div>
      <br />
      <div v-if="originalCardNo" class="barcode flexV width center">
          <barcode
            :value="originalCardNo"
            format="code128"
            width="2.5"
            height="60px"
            displayValue="true"
            :margin="5"
          >
          </barcode>
      </div>
    </section>
  </div>  -->
  <section>
    <div class="top-bar">
      <div class="nav">
        <a @click="goBack"><i class="fa-solid fa-angle-left"></i></a>
      </div>
      <h2>手機條碼載具</h2>
      <div class="nav right">
        <a v-show="originalCardNo" class="textButton fM third" @click.prevent="popDelete"
          >解除綁定</a
        >
      </div>
    </div>
  </section>
  <div class="main-container">
    <section class="main-content">
      <div class="">
        <div class="form-list">
          <b><i>手機條碼載具</i><br /></b>
          <span>
            <input
              name="cardNo"
              type="text"
              placeholder="輸入手機條碼載具"
              v-model="user.cardNo"
              @input="isCarrierBarcode"
            />
          </span>
        </div>
        <div class="form-msg">
          <div class="left">{{ errors.cardNo }}</div>
        </div>
        <div class="form-list">
          <b><i>載具驗證碼</i><br /></b>
          <span>
            <input
              name="cardEncrypt"
              type="password"
              placeholder="輸入驗證碼"
              v-model="cardEncrypt"
              @change="isCardEncrypt"
            />
          </span>
        </div>
        <div class="form-msg">
          <div class="left">{{ errors.cardEncrypt }}</div>
          <div class="right">
            <a href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC511W/" target="_blank"
              >忘記驗證碼</a
            >
          </div>
        </div>
        <a
          :class="[
            'bottom-btn',
            { disable: !user.cardNo || !cardEncrypt || errors.cardNo || errors.cardEncrypt },
          ]"
          @click.prevent="save"
        >
          <span class="">儲存</span>
        </a>
        <br />
        <div style="text-align: center">
          <span class="">
            載具條碼請至財政部網站進行申請
            <br />
            <a
              class="textButton"
              href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC501W/"
              target="_blank"
            >
              財政部電子發票整合服務平台
            </a>
          </span>
        </div>
      </div>
      <br /><br />
      <div v-if="originalCardNo" style="text-align: center">
        <barcode
          :value="originalCardNo"
          format="code128"
          width="2.5"
          height="60px"
          displayValue="true"
          :margin="5"
        >
        </barcode>
      </div>
    </section>
  </div>
  <ErrorPopup v-if="errorModalOpen" @closeModal="errorModalOpen = false" :content="popupContent" />
  <SuccessPopup
    v-if="successModalOpen"
    @closeModal="successModalOpen = false"
    :content="popupContent"
  />

  <ConfirmPopup
    v-if="confirmModalOpen"
    @closeModal="confirmModalOpen = false"
    @clickConfirmBtn="removeCarrier"
    :content="popupConfirmContent"
  />
</template>

<script>
import { reactive, toRefs, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ErrorPopup from "@/components/ErrorPopup.vue";
import SuccessPopup from "@/components/SuccessPopup.vue";
import ConfirmPopup from "@/components/ConfirmPopup.vue";
import Vue3Barcode from "vue3-barcode";
import { getUserData, updateUserCarrier } from "@/apis/users";
import { useRouter } from "vue-router";

export default {
  name: "CarrierEdit",
  components: {
    LoadingSpinner,
    ErrorPopup,
    SuccessPopup,
    ConfirmPopup,
    barcode: Vue3Barcode,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      originalCardNo: "",
      successMessage: null,
      isProcessing: false,
      cardEncrypt: "",
      errors: {
        cardNo: null,
        cardEncrypt: null,
      },
      errorModalOpen: false,
      successModalOpen: false,
      confirmModalOpen: false,
      popupContent: {
        msg: "",
        subMsg: "",
        redirectTo: "",
      },
      popupConfirmContent: {
        msg: "確定解除綁定載具？",
        subMsg: "解除綁定後部分功能將無法正常使用",
        redirectTo: "",
      },
    });
    const { user } = store.state;

    onMounted(() => {
      state.originalCardNo = user?.cardNo?.trim();
    });

    const isCarrierBarcode = (event) => {
      // console.log("check barcode", event.target.value);
      let code = event.target.value;
      if (code && code.trim()) {
        let patt = /^\/[0-9A-Z.+-]{7}$/;
        console.log("check barcode", patt.test(code.trim()));
        if (patt.test(code.trim())) {
          state.errors.cardNo = null;
        } else {
          state.errors.cardNo = "載具條碼格式錯誤";
        }
      } else {
        state.errors.cardNo = "必填";
      }
    };

    const isCardEncrypt = (event) => {
      console.log("check card encrypt", event.target.value);
      let value = event.target.value;
      if (value && value.trim()) {
        state.errors.cardEncrypt = null;
      } else {
        state.errors.cardEncrypt = "必填";
      }
    };

    const save = () => {
      console.log("save", user);
      if (state.errors.cardNo || state.errors.cardEncrypt) return;
      state.isProcessing = true;
      let data = Object.assign({}, user);
      data.cardNo = data.cardNo.trim();
      data.cardEncrypt = state.cardEncrypt ? state.cardEncrypt.trim() : null;
      console.log("data:", data);
      updateUserCarrier(data)
        .then((res) => {
          console.log("res: ", res);
          // popSuccess("載具綁定成功");
          state.popupContent = {
            msg: "載具綁定成功",
            // subMsg: "載具綁定成功",
            redirectTo: "Home",
          };
          state.successModalOpen = true;
          getUserData(user).then((response) => {
            const _user = response.data;
            console.log("user:" + JSON.stringify(_user));
            store.commit("getUser", {
              userId: user.userId,
              // memberId: +user._json.oid.split("/")[1],
              memberId: user.memberId,
              cardNo: _user?.cardNo ? _user.cardNo.trim() : null,
              phoneNumber: user._json?.phoneNumber,
              merchantId: user.merchantId || process.env.VUE_APP_MERCHANT_ID,
            });
            state.originalCardNo = _user?.cardNo ? _user.cardNo.trim() : null;
          });
        })
        .catch((err) => {
          console.log("err: ", err);
          state.popupContent = {
            msg: "載具綁定失敗",
            // subMsg: "載具綁定成功",
          };
          state.errorModalOpen = true;
        })
        .finally(() => {
          state.isProcessing = false;
        });
    };

    const removeCarrier = () => {
      state.isProcessing = true;
      popDeleteClose();
      let data = Object.assign({}, user);
      data.cardNo = null;
      data.cardEncrypt = null;
      console.log("removeCarrier", data);
      updateUserCarrier(data)
        .then((res) => {
          console.log("updated res: ", res);
          // popSuccess("載具解綁成功");
          state.popupContent = {
            msg: "載具解綁成功",
            // subMsg: "載具解綁成功",
            redirectTo: "Home",
          };
          state.successModalOpen = true;
          getUserData(user).then((response) => {
            const _user = response.data;
            console.log("user:" + JSON.stringify(_user));
            store.commit("getUser", {
              userId: user.userId,
              // memberId: +user._json.oid.split("/")[1],
              memberId: user.memberId,
              cardNo: null,
              phoneNumber: user._json?.phoneNumber,
              merchantId: user.merchantId || process.env.VUE_APP_MERCHANT_ID,
            });
            state.originalCardNo = null;
          });
          state.cardEncrypt = "";
        })
        .catch((err) => {
          console.log("err: ", err);
          state.popupContent = {
            msg: "載具解綁失敗",
            // subMsg: "載具綁定成功",
          };
          state.errorModalOpen = true;
        })
        .finally(() => {
          state.isProcessing = false;
        });
    };

    const popDelete = () => {
      state.confirmModalOpen = true;
    };
    const popDeleteClose = () => {
      state.confirmModalOpen = false;
    };

    const goBack = () => {
      // console.log("original:" + state.originalCardNo);
      // 避免沒按儲存就回上頁
      user.cardNo = state.originalCardNo;
      router.push("/");
    };

    watchEffect(() => {
      if (!user) return;
      // state.originalCardNo = user.cardNo ? user.cardNo : null;
    });

    return {
      ...toRefs(state),
      user,
      isCarrierBarcode,
      isCardEncrypt,
      save,
      removeCarrier,
      popDelete,
      goBack,
    };
  },
};
</script>
<!-- 
<style scoped>
@import "../assets/css/ty.css";
@import "../assets/css/unique.css";
</style> -->
